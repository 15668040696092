import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../../api/contracts";
import {Table, Button, Select, Tooltip, message, Form, List, Input, notification, Card, Pagination, Modal} from 'antd';
import {getContractByName, getContractByContract} from "../../../api/connectContract";
import {dealMethod, viewMethod} from "../../../utils/contractUtil"
import {useNavigate, useLocation} from "react-router-dom";
import SwapViewStyle from "./style";
import judgeStatus from "../../../utils/judgeStatus";
import {showNum} from "../../../utils/bigNumberUtil";
import BigNumber from "bignumber.js";

import addressMap from "../../../api/addressMap";
import {MaxUint256, SPTCoinDecimal} from "../../../config/constants";
import {getBonusRecords, getRecords,} from "../../../graph/LeaderboardBonus1GQL";

import {dealTime} from "../../../utils/timeUtil";
import {formatAddress} from "../../../utils/publicJs";
import develop from "../../../env"
import {getBTCPrice} from "../../../api/coinPrice";
import {useTranslation} from "react-i18next";

let timer2 = null
const SwapView = (props) => {
    let {state, dispatch} = useConnect();

    const [usdtBalance, setUSDTBalance] = useState(0)
    const [btcPrice, setBTCPrice] = useState(0)
    const [mySPTBalance, setMySPTBalance] = useState(0)

    const [srtDecimal, setSRTDecimal] = useState(18)
    const [countDownTime, setCountDownTime] = useState(undefined)
    const [owner, setOwner] = useState(" ")
    const [bonusAccountNum, setBonusAccounts] = useState(0)
    const [myPeriodAmount, setMyPeriodAmount] = useState(0)
    const [myLastPeriodAmount, setMyLastPeriodAmount] = useState(0)

    const [allowance, setAllowance] = useState(0)
    const [rewardThreshold, setRewardThreshold] = useState(0)
    const [bonusRecord, setBonusRecord] = useState([])
    const [cycle, setCycle] = useState()
    const [startTime, setStartTime] = useState("")
    const [startPeriodTime, setStartPeriodTime] = useState("")

    const [isMolOpen, setMolOpen] = useState(false)

    const [pageCount, setPageCount] = useState(10)
    const [curPage, setCurPage] = useState(1)
    const [total, setTotal] = useState(0)

    const [overAllArr, setOverallArr] = useState([])
    const [selectOption, setSelectOption] = useState([])
    const [periodArr, setPeriodArr] = useState([])

    const [curPeriod, setCurPeriod] = useState()
    const [curPeriodName, setCurPeriodName] = useState()

    const [form] = Form.useForm();
    const history = useNavigate();
    const {t} = useTranslation();

    const location = useLocation()
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("LeaderboardBonus1", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
            },
        });
    };

    const onChangePage = async (page) => {
        await setCurPage(page)
    }


    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("LeaderboardBonus1", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return dealMethod(contractTemp, state.account, name, params)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }
    const getRewardThreshold = async () => {
        const res = await handleViewMethod("rewardThreshold", [])
        setRewardThreshold(BigNumber(res).div(10 ** SPTCoinDecimal).toString())
    }
    const getCycle = async () => {
        const res = await handleViewMethod("cycle", [])
        setCycle(res)
    }
    const getStartDividendTime = async () => {
        try {
            let res
            const startDividendTime = await handleViewMethod("startDividendTime", [])
            if (BigNumber(startDividendTime).gt(new Date().getTime() / 1000)) {
                res = startDividendTime
            } else {
                res = await handleViewMethod("getStartDividendTime", [])
            }
            setStartTime(res)
            const res2 = await handleViewMethod("initStartTime", [])
            setStartPeriodTime(res2)
        } catch (e) {
            console.log(e)
        }
    }
    const getPoolBalance = async () => {
        try {
            let contractTemp = await getContractByContract("erc20", addressMap["USDT"].address, state.api,)
            const decimal = await viewMethod(contractTemp, state.account, "decimals", [])
            const res = await viewMethod(contractTemp, state.account, "balanceOf", [addressMap["LeaderboardBonus1"].address])

            setSRTDecimal(decimal)
            setUSDTBalance(BigNumber(res).div(10 ** decimal).toString())
        } catch (e) {
            console.error(e)
        }
    }
    const getMySPTBalance = async () => {
        try {
            let contractTemp = await getContractByContract("erc20", addressMap["SPT"].address, state.api,)
            const mySPT = await viewMethod(contractTemp, state.account, "balanceOf", [state.account])
            setMySPTBalance(BigNumber(mySPT).div(10 ** SPTCoinDecimal).toString())
        } catch (e) {
            console.error(e)
        }
    }

    const getAdmin = async () => {
        const owner = await handleViewMethod("owner", [])

        const usdt = await handleViewMethod("usdt", [])
        console.log(usdt)
        setOwner(owner)
    }
    const getCurrentBonusAmount = async () => {
        try {
            const res = await handleViewMethod("getCurrentBonusAmount", [])
            setBonusAccounts(res)
        } catch (e) {
            console.log(e)
        }
    }
    const dividendToken = () => {
        handleDealMethod("dividendToken", [])
        setTimeout(() => {
            getData()
        }, 2000)
    }
    const handleApprove = async () => {
        let contractTemp = await getContractByContract("erc20", addressMap["SRT"].address, state.api,)
        await dealMethod(contractTemp, state.account, "approve", [addressMap["Swap"].address, MaxUint256])
        getAllowance()
    }
    const getAllowance = async () => {
        let contractTemp = await getContractByContract("erc20", addressMap["SRT"].address, state.api,)
        const res = await viewMethod(contractTemp, state.account, "allowance", [state.account, addressMap["Swap"].address])
        setAllowance(res)
    }
    useEffect(async () => {
        if (startPeriodTime) {
            try {
                let accountArr = []

                const res = await getRecords()
                if (res && res.data) {
                    const record = res.data.records
                    // all list
                    record.forEach(item => {
                        let hasAddr = false
                        accountArr.forEach(accountItem => {
                            if (accountItem.user.toLowerCase() == item.user.toLowerCase()) {
                                hasAddr = true
                                accountItem.amount = BigNumber(accountItem.amount).plus(item.amount)
                            }
                        })
                        if (!hasAddr) {
                            accountArr.push({...item})
                        }
                    })

                    accountArr.sort((a, b) => {
                        return b.amount - a.amount
                    })

                    const myItem = {
                        amount: 0,
                        user: state.account,
                        index: accountArr.length + 1
                    }
                    setTimeout(() => {
                        accountArr.forEach((item, index) => {
                            item.index = index + 1
                            if (item.user.toLowerCase() == state.account.toString().toLowerCase()) {
                                myItem.amount = item.amount
                                myItem.index = item.index
                            }
                        })
                        accountArr.unshift(myItem)
                    }, 100)
                    setOverallArr(accountArr)


                    //     list2

                    let periodArr = [], selectOption = [], periodNum = 0
                    const nowT = parseInt(new Date().getTime() / 1000)
                    let tempTime = parseInt(startPeriodTime)


                    for (let i = 0; BigNumber(tempTime).lt(nowT); i++) {
                        let tempArr = []
                        const endTime = BigNumber(tempTime).plus(cycle)
                        selectOption.push({
                            label: "Period " + (periodNum + 1) + " (" + dealTime(tempTime) + "~" + dealTime(endTime) + ")",
                            value: periodNum
                        })
                        periodNum++
                        for (let j = 0; j < record.length; j++) {
                            const recordItem = record[j]
                            if (BigNumber(recordItem.blockTime).gt(tempTime) && BigNumber(recordItem.blockTime).lt(endTime) || BigNumber(recordItem.blockTime).eq(tempTime)) {
                                tempArr.push({...recordItem})
                            }
                        }
                        periodArr.push(tempArr)
                        tempTime = BigNumber(tempTime).plus(cycle)
                    }

                    setSelectOption(selectOption)

                    // Sort
                    let newArr = []

                    periodArr.forEach(arrItem => {
                        const tempArr = []
                        arrItem.forEach(item => {
                            let hasAddr = false
                            tempArr.forEach(accountItem => {
                                if (accountItem.user == item.user) {
                                    hasAddr = true
                                    accountItem.amount = BigNumber(accountItem.amount).plus(item.amount).toString()
                                }
                            })
                            if (!hasAddr) {
                                tempArr.push({...item})
                            }
                        })

                        tempArr.sort((a, b) => {
                            return b.amount - a.amount
                        })
                        newArr.push(tempArr)
                    })
                    setPeriodArr(newArr)
                    if (newArr.length > 0) {
                        setCurPeriod(newArr.length - 1)
                        getBonusAccountNum(newArr)


                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
    }, [startPeriodTime])
    //获取个人本周期获取spt
    useEffect(() => {
        let periodMyAmount = 0
        if (periodArr.length <= 0) {
            return
        }
        periodArr[curPeriod].forEach(item => {
            if (item.user.toLowerCase() == state.account.toString().toLowerCase()) {
                periodMyAmount = BigNumber(periodMyAmount).plus(item.amount)
            }
        })
        setMyPeriodAmount(periodMyAmount)
    }, [curPeriod])
    const getBonusAccountNum = async (periodArr) => {
        const curArr = periodArr[periodArr.length - 1]
        let tempNum = 0
        let periodMyAmount = 0
        curArr.forEach(item => {
            if (BigNumber(item.amount).div(10 ** SPTCoinDecimal).gt(rewardThreshold)) {
                tempNum++
            }

            //     获取个人本周期获取spt
            if (item.user.toLowerCase() == state.account.toString().toLowerCase()) {
                periodMyAmount = BigNumber(periodMyAmount).plus(item.amount)
            }

        })
        setBonusAccounts(tempNum)

        setMyLastPeriodAmount(periodMyAmount)
        setMyPeriodAmount(periodMyAmount)
    }
    const getData = async (page) => {
        getPoolBalance()
        getMySPTBalance()
        getRewardThreshold()
        getStartDividendTime()
        getCycle()
        getAdmin()
        // getCurrentBonusAmount()

        try {
            const res = await getBonusRecords()
            setBonusRecord(res.data.bonusRecords)
        } catch (e) {
            console.log(e)
        }
    }

    const dealData2 = () => {
        const tap = BigNumber(new Date().getTime() / 1000).minus(startTime).toFixed(0).toString()
        let cd = tap % cycle
        cd = cycle - cd
        const d = parseInt(cd / (24 * 60 * 60) + '');
        const h = parseInt(((cd / (60 * 60)) % 24) + '');
        const m = parseInt(((cd / 60) % 60) + '');
        const s = parseInt((cd % 60) + '');

        setCountDownTime(` ${d}d ${h}h ${m}m ${s}s`);
        cd--;

        timer2 = setTimeout(() => {
            dealData2();
        }, 1000);
    };

    useEffect(() => {
        if (startTime > 0 && cycle > 0) {
            dealData2()
        }
        return () => {
            clearInterval(timer2);
        }
    }, [startTime, cycle]);
    useEffect(async () => {
        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        await getData()

        try {
            let btcPrice = await getBTCPrice()
            if (btcPrice && btcPrice.price) {
                setBTCPrice(btcPrice.price)
            }
        } catch (e) {
            console.log(e)
        }

    }, [state.account]);

    return (
        <SwapViewStyle>
            <Modal className="model-dialog" title="Overall Leaderboard" open={isMolOpen}
                   footer={[]}
                   onCancel={() => {
                       setMolOpen(false)
                   }}>
                <div className="panel-box">
                    <div className="panel-container">

                        <div className='superdao-list-box  leaderboard-list'>
                            <div className='list-header nft-header'>
                                <div className='col'>
                                    {t("No.")}
                                </div>

                                <div className='col'>
                                    {t("Address")}
                                </div>

                                <div className='col'>
                                    SPT
                                </div>
                            </div>
                            {overAllArr.map((item, index) => {
                                return (
                                    index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                    <div className='list-item nft-item' key={index}>
                                        <div className='col no'>
                                            {item.index}
                                        </div>
                                        <div className='col'>
                                            {formatAddress(item.user)}
                                        </div>
                                        <div
                                            className={"col " + (BigNumber(item.amount).div(10 ** SPTCoinDecimal).gt(rewardThreshold) ? "active" : "")}>
                                            {showNum(BigNumber(item.amount).div(10 ** SPTCoinDecimal))}
                                        </div>

                                    </div>)
                            })}

                        </div>
                        <div className="pagination">

                            <Pagination current={curPage} showSizeChanger
                                        onShowSizeChange={handleShowSizeChange}
                                        onChange={onChangePage} total={total}
                                        defaultPageSize={pageCount}/>

                        </div>
                    </div>
                </div>

            </Modal>
            <div className="panel-box">
                <div className="panel-title">
                    {owner.toLowerCase() == state.account.toString().toLowerCase() &&
                        <Button style={{float: "right"}} onClick={() => {
                            history("/LeaderboardBonus1Admin")
                        }}>Admin</Button>
                    }

                </div>
                <div className=" detail-box" style={{marginTop: "20px"}}>
                    <div className="panel-title">
                        {t("Details")}
                    </div>
                    <div className="flex-box usdt-box">
                        <div className="flex-box total-usdt">
                            <div className="value">
                                {usdtBalance}<span>USDT</span>
                            </div>
                            <div className="name">
                                {t("Total Prize Pool")}
                            </div>
                        </div>
                        <div className="flex-box total-usdt">
                            <div className="value">
                                {bonusAccountNum}
                            </div>
                            <div className="name">
                                {t("Qualified Accounts")}
                            </div>
                        </div>
                        <div className="flex-box total-usdt">
                            <div className="value">
                                {bonusAccountNum > 0 ? BigNumber(usdtBalance).div(bonusAccountNum).toFixed(2) : 0}<span>USDT</span>
                            </div>
                            <div className="name">
                                {t("Reward per account")}
                            </div>
                        </div>

                    </div>
                    <div className="detail-list">
                        <div className="flex-box">
                            <div className="value">
                                {dealTime(startTime)}
                            </div>
                            <div className="name">
                                {t("Start Time(UTC)")}
                            </div>
                        </div>
                        <div className="flex-box">

                            <div className="value">
                                {dealTime(BigNumber(startTime).plus(cycle))}
                            </div>
                            <div className="name">
                                {t("End Time(UTC)")}
                            </div>
                        </div>


                        <div className="flex-box">
                            <div className="value">
                                {showNum(rewardThreshold)} SPT
                            </div>
                            <div className="name">
                                {t("Bonus Threshold")}
                                <Tooltip className="Tooltip"
                                         title={t("In one bonus period, the number of new SPTs reaches") + " " + rewardThreshold}>
                                    <svg style={{marginLeft: "8px"}} t="1701762676678" className="icon"
                                         viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                         p-id="5925" width="20" height="64">
                                        <path
                                            d="M514.048 54.272q95.232 0 178.688 36.352t145.92 98.304 98.304 145.408 35.84 178.688-35.84 178.176-98.304 145.408-145.92 98.304-178.688 35.84-178.176-35.84-145.408-98.304-98.304-145.408-35.84-178.176 35.84-178.688 98.304-145.408 145.408-98.304 178.176-36.352zM515.072 826.368q26.624 0 44.544-17.92t17.92-43.52q0-26.624-17.92-44.544t-44.544-17.92-44.544 17.92-17.92 44.544q0 25.6 17.92 43.52t44.544 17.92zM567.296 574.464q-1.024-16.384 20.48-34.816t48.128-40.96 49.152-50.688 24.576-65.024q2.048-39.936-8.192-74.752t-33.792-59.904-60.928-39.936-87.552-14.848q-62.464 0-103.936 22.016t-67.072 53.248-35.84 64.512-9.216 55.808q1.024 26.624 16.896 38.912t34.304 12.8 33.792-10.24 15.36-31.232q0-12.288 7.68-30.208t20.992-34.304 32.256-27.648 42.496-11.264q46.08 0 73.728 23.04t25.6 57.856q0 17.408-10.24 32.256t-26.112 28.672-33.792 27.648-33.792 28.672-26.624 32.256-11.776 37.888l1.024 38.912q0 15.36 14.336 29.184t37.888 14.848q23.552-1.024 37.376-15.36t12.8-32.768l0-24.576z"
                                            p-id="5926" fill="#ffffff"></path>
                                    </svg>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="flex-box">
                            <div className="value">
                                {showNum(cycle / 86400, 3)} Days
                            </div>
                            <div className="name">
                                {t("Bonus Period")}
                            </div>
                        </div>


                    </div>

                    <div className="coount-down">
                        <div className="title" style={{textTransform: "uppercase"}}>
                            {t("Countdown")}
                        </div>
                        <div className="time-box">
                            {countDownTime}
                        </div>
                        <div className="can-receive">
                            {t("USDT(BTC) I can receive")}
                        </div>
                        <div className="usdt-btc" style={{color: "rgb(228, 134, 134)"}}>
                            {(BigNumber(myPeriodAmount).div(10 ** SPTCoinDecimal).gt(rewardThreshold) || (BigNumber(myPeriodAmount).div(10 ** SPTCoinDecimal).eq(rewardThreshold))) ?
                                <>
                                    {bonusAccountNum > 0 ? BigNumber(usdtBalance).div(bonusAccountNum).toFixed(2) : 0}
                                    ({bonusAccountNum > 0 && btcPrice > 0 ? BigNumber(usdtBalance).div(bonusAccountNum).div(btcPrice).toFixed(7) : 0})
                                </>
                                :
                                <div className="unqualified">
                                    {t("Unqualified")}
                                </div>
                            }

                        </div>
                        <Button
                            className="bonus-btn"
                            onClick={() => {
                                dividendToken()
                            }} type="primary">
                            {t("Bonus")}
                        </Button>
                    </div>
                </div>
            </div>
            <div className='panel-box'>
                <div className='panel-container'>
                    <div className="list-box">

                        <div className="list-item" style={{width: "100%"}}>
                            <div className='panel-title flex-box'
                                 style={{alignItems: "center", justifyContent: "space-between"}}>
                                <h2>
                                    {t("Period Leaderboard")}
                                    <Tooltip className="Tooltip"
                                             title={t("Period Leaderboard only displays the SPT Increment of") + " 1000"}>
                                        <svg style={{marginLeft: "8px"}} t="1701762676678" className="icon"
                                             viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                             p-id="5925" width="20" height="64">
                                            <path
                                                d="M514.048 54.272q95.232 0 178.688 36.352t145.92 98.304 98.304 145.408 35.84 178.688-35.84 178.176-98.304 145.408-145.92 98.304-178.688 35.84-178.176-35.84-145.408-98.304-98.304-145.408-35.84-178.176 35.84-178.688 98.304-145.408 145.408-98.304 178.176-36.352zM515.072 826.368q26.624 0 44.544-17.92t17.92-43.52q0-26.624-17.92-44.544t-44.544-17.92-44.544 17.92-17.92 44.544q0 25.6 17.92 43.52t44.544 17.92zM567.296 574.464q-1.024-16.384 20.48-34.816t48.128-40.96 49.152-50.688 24.576-65.024q2.048-39.936-8.192-74.752t-33.792-59.904-60.928-39.936-87.552-14.848q-62.464 0-103.936 22.016t-67.072 53.248-35.84 64.512-9.216 55.808q1.024 26.624 16.896 38.912t34.304 12.8 33.792-10.24 15.36-31.232q0-12.288 7.68-30.208t20.992-34.304 32.256-27.648 42.496-11.264q46.08 0 73.728 23.04t25.6 57.856q0 17.408-10.24 32.256t-26.112 28.672-33.792 27.648-33.792 28.672-26.624 32.256-11.776 37.888l1.024 38.912q0 15.36 14.336 29.184t37.888 14.848q23.552-1.024 37.376-15.36t12.8-32.768l0-24.576z"
                                                p-id="5926" fill="#ffffff"></path>
                                        </svg>
                                    </Tooltip>
                                </h2>
                                <Select
                                    value={curPeriod}
                                    onChange={(value, item) => {
                                        setCurPeriod(value)
                                        setCurPeriodName(item.label)
                                    }}
                                    className='select-box'
                                    options={selectOption}
                                    style={{

                                        width: "360px",
                                        color: '#FFF', marginLeft: "10px"
                                    }}

                                />
                                {/*<Button*/}
                                {/*    onClick={() => {*/}
                                {/*        setMolOpen(true)*/}
                                {/*    }}>*/}
                                {/*    Overall Leaderboard</Button>*/}
                            </div>

                            <div className="my-spt">
                                <div className="content-item">
                                    <div className="value">
                                        {BigNumber(mySPTBalance).toFixed(0)}
                                    </div>
                                    <h3 className="name">
                                        {t("My Total SPT")}
                                    </h3>

                                </div>
                                <div className="content-item">
                                    <div className="value">
                                        {BigNumber(myPeriodAmount).div(10 ** SPTCoinDecimal).toFixed(0)}
                                    </div>
                                    <h3 className="name">
                                        {t("My Period")} {curPeriod + 1} SPT
                                    </h3>
                                </div>

                                <div className="content-item">
                                    <div className="value">
                                        {BigNumber(rewardThreshold).minus(BigNumber(myPeriodAmount).div(10 ** SPTCoinDecimal)).gt(0) ?
                                            BigNumber(rewardThreshold).minus(BigNumber(myPeriodAmount).div(10 ** SPTCoinDecimal)).toFixed(0)
                                            : "0"}
                                    </div>
                                    <h3 className="name">
                                        {t("Shortage of SPT")}
                                    </h3>
                                </div>
                            </div>

                            <div className='superdao-list-box list1 nft-list-box'>
                                <div className='list-header nft-header'>
                                    <div className='col'>
                                        {t("No.")}
                                    </div>
                                    {state.isMobile ? <div className='col' style={{width: "500px"}}>
                                        {t("Address")}
                                    </div> : <div className='col' style={{width: "500px"}}>
                                        {t("Address")}
                                    </div>}

                                    <div className='col'>
                                        SPT
                                    </div>
                                </div>
                                {curPeriod >= 0 && periodArr[curPeriod].map((item, index) => {
                                    if (BigNumber(item.amount).div(10 ** SPTCoinDecimal).gt(1000) || BigNumber(item.amount).div(10 ** SPTCoinDecimal).eq(1000)) {
                                        return (
                                            index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                            <div className='list-item nft-item' key={index}>
                                                <div className='col no'>
                                                    {(index + 1)}
                                                </div>
                                                {state.isMobile ? <div className='col'>
                                                    {formatAddress(item.user)}
                                                </div> : <div className='col' style={{width: "500px"}}>
                                                    {item.user}
                                                </div>}
                                                <div
                                                    className={"col " + (BigNumber(item.amount).div(10 ** SPTCoinDecimal).gt(rewardThreshold) ? "active" : "")}>
                                                    {showNum(BigNumber(item.amount).div(10 ** SPTCoinDecimal))}
                                                </div>

                                            </div>)
                                    }

                                })}

                            </div>
                            <div className="pagination">

                                <Pagination current={curPage} showSizeChanger
                                            onShowSizeChange={handleShowSizeChange}
                                            onChange={onChangePage} total={total}
                                            defaultPageSize={pageCount}/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='panel-box bonus-record'>
                <div className='panel-container'>
                    <div className='panel-title'>
                        {t("Bonus Records")}
                    </div>
                    <div className='superdao-list-box nft-list-box bonus-record'>
                        <div className='list-header nft-header'>
                            <div className='col'>
                                {t("No.")}
                            </div>

                            <div className='col'>
                                {t("Operator")}
                            </div>
                            <div className='col'>
                                BTC
                            </div>
                            <div className='col'>
                                {t("Threshold")}
                            </div>
                            <div className='col'>
                                {t("Period")}
                            </div>
                            <div className='col'>
                                {t("Bonus Account")}
                            </div>
                            <div className="col">
                                {t("Time(UTC)")}
                            </div>
                            <div className="col detail-btn">
                                {t("Detail")}
                            </div>
                        </div>
                        {bonusRecord.map((item, index) => {
                            return (
                                index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                                <div className='list-item nft-item' key={index}>
                                    <div className='col no'>
                                        {index + 1}
                                    </div>
                                    <div className='col'>
                                        {formatAddress(item.operate)}
                                    </div>
                                    <div className='col'>
                                        {BigNumber(item._amount).div(10 ** 18).toFixed(3)}
                                    </div>
                                    <div className='col'>
                                        {BigNumber(item._threshold).div(10 ** SPTCoinDecimal).toFixed(0)}

                                    </div>
                                    <div className='col'>
                                        {item.period}

                                    </div>
                                    <div className='col'>
                                        {formatAddress(item._to)}
                                    </div>

                                    <div className='col'>
                                        {dealTime(item.blockTimestamp)}
                                    </div>
                                    <div className="col detail-btn">
                                        <Button onClick={() => {
                                            window.open(develop.ethScan + "/tx/" + item.transactionHash)
                                        }}>   {t("Detail")}</Button>
                                    </div>
                                </div>)
                        })}

                    </div>
                    <div className="pagination">

                        <Pagination current={curPage} showSizeChanger
                                    onShowSizeChange={handleShowSizeChange}
                                    onChange={onChangePage} total={total}
                                    defaultPageSize={pageCount}/>

                    </div>
                </div>
            </div>


        </SwapViewStyle>
    )
}
export default SwapView
