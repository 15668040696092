import React, { useState } from 'react';
import { Component, useEffect, useReducer } from "react";
import logo from "../../imgs/logo.png"
import icon1 from "../../imgs/11.png"
import icon2 from "../../imgs/22.png"
import icon3 from "../../imgs/33.png"
import icon4 from "../../imgs/44.png"
import icon5 from "../../imgs/55.png"
import icon6 from "../../imgs/66.png"
import icon7 from "../../imgs/77.png"
import icon8 from "../../imgs/88.png"
import icon9 from "../../imgs/99.png"
import { useLocation, useNavigate } from "react-router-dom";
import footerMap from "../../config/footerMap";
import { Menu } from 'antd';
import SuperbitdaoFooterStyle from "./SuperbitdaoFooterStyle";
import {useTranslation} from "react-i18next";
function getItem(label, key, type) {
    return {
        key,
        label,
        type,
    };
}


const items = footerMap;
const SuperbitdaoFooter = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState(["SBDPurchase"]);
    const [selectNav, setSelectNav] = useState("SBDPurchase");
    const [openKeys, setOpenKeys] = useState(['SBDPurchase']);
    const rootSubmenuKeys = footerMap.map(items => {
        return items.key
    });
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    const history = useNavigate();
    const location = useLocation()
    const { t } = useTranslation();

    const goPage = (obj) => {
        setSelectedKeys(obj.keyPath)
        history("/" + obj.key);
        // setSelectNav(obj.keyPath[1])
    }
    const onOpenChange = (keys) => {
        console.log(keys)
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    useEffect(() => {

        let keyPath = []
        const curKey = location.pathname.substring(1, location.pathname.length)
        footerMap.forEach(navObj => { 
            return
            navObj.children.forEach(async nav => {
                if (nav.key == curKey) {
                    await setSelectNav(navObj.key)
                    setOpenKeys([navObj.key])
                    keyPath.push(navObj.key)
                    keyPath.push(nav.key)
                    setSelectedKeys(keyPath)
                }
            })
        })
    }, [])

    return (
        <SuperbitdaoFooterStyle>
            <div className="footer">
                <div className="lb">
                    <div className="bottom-box-box">
                        <div
                            className="navBox"
                            style={{
                                width: '100%'
                            }}
                        >

                            <Menu
                                className="menu"
                                mode="inline"
                                defaultSelectedKeys={[]}
                                defaultOpenKeys={[]}
                                selectedKeys={selectedKeys}

                                onOpenChange={onOpenChange}
                                theme="dark"
                                inlineCollapsed={collapsed}
                                items={items}
                                onClick={(e) => goPage(e)}
                            />

                        </div>
                    </div>
                </div>
                <div className="left">
                    <img className="logo" src={logo} alt="" />
                    <div className="link-list">
                    <div className="copyright">
                        Copyright ©Superbitdao
                    </div>
                    </div>
                    {/* <div className="copyrightphone">
                        Copyright ©Superbitdao
                    </div> */}
                </div>
                <div className="right">
                    <div className="link-box">
                        <div className="link-col">
                            <div className="link-row title" onClick={() => {
                            history("/SBDPurchase")
                        }}>
                            Funding
                            </div>

                        </div>
                        <div className="link-col">
                            <div className="link-row title">
                                Token
                            </div>

                        </div>
                     
                        <div className="link-col">
                            <div className="link-row title" 
                            >
                                NFT
                            </div>

                        </div>
                        <div className="link-col">
                            <div className="link-row title">
                                {t("Lock Mining")}
                            </div>

                        </div>
                        <div className="link-col">
                            <div className="link-row title">
                            {t("Swap")}:
                            </div>

                        </div>
                        <div className="link-col">
                            <div className="link-row title" onClick={() => {
                            history("/SBDPurchaseManage")
                        }}>
                                Admin
                            </div>


                        </div>

                    </div>
                    <div className="link-list">
                        <a href="https://twitter.com/Superbitdaolab" target="_blank" className="link">
                            <img className="icon" src={icon1} alt="" />
                          
                        </a>
                        <a href="https://t.me/Superbitdao" target="_blank" className="link">
                           
                            <img className="icon" src={icon2} alt="" />
                        </a>
                        <a href="https://github.com/Superbitdao" target="_blank" className="link">
                          <img className="icon" src={icon6} alt="" />
                        </a>
                        <a href="" target="_blank" className="link">
                           <img className="icon" src={icon9} alt="" />
                        </a>
                    </div>
                
                </div>
            </div>
        </SuperbitdaoFooterStyle>
    )

}
export default SuperbitdaoFooter
